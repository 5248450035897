import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const AssetManagement = () => {
    return (
        <Layout>
            <Seo title="Asset Management" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Asset Management</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <p className="card-text">
                                        The company has created a unique platform that allows investors exposure to Dry Shipping
                                        indexes without the need to buy shares of shipping companies or having to invest in ships.
                                    </p>
                                    <p>
                                        Delta platform allows Owners, Investors and Financers to invest in their targeted sector in dry
                                        shipping that aims to replicates the returns of Baltic Dry Index, BCI, BPI, BSI or BHSI.
                                    </p>
                                    <p>
                                        Investors can chose to gain Time Charter Market exposures i.e. have full “Operating leverage”
                                        i.e upside swing of the market with no “Financial leverage” i.e. no debt with investing horizon
                                        of as little as seven months or longer depending on the investors requirement.
                                    </p>
                                    <p>
                                        More details about asset management can be found on the Delta Corp Ship Management{" "}
                                        <Link to="/shipmanagement/">website</Link>.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/container4.jpg"
                                    aspectRatio={16 / 9}
                                    width={696}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AssetManagement
